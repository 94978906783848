<template>
  <div>
    <dashboard-page-title  :showAddBtn="false" :showFilter="false" :showSearch="false" :showExportation="false">العيادات الغير مفعلة</dashboard-page-title>
    <main-table :fields="fields" list_url="admin/clinics" :reloadTable="reloadTable" :additional-url="`&status=processing`" :baseURL="VUE_APP_VETE_LINK"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_VETE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_VETE_LINK,
      loading: false,
      clinicInfo: '',
      reloadTable: false,
      fields: [
        { label: 'Id', key: 'id', class: 'text-right', sortable: true },
        { label: 'الاسم', key: 'clinic_name', class: 'text-right' },
        { label: 'البريد الإلكترونى', key: 'email', class: 'text-right' },
        { label: 'رقم التليفون', key: 'primary_phone_number', class: 'text-right' },
        { label: 'تاريخ الطلب', key: 'created_at', class: 'text-right' },
        { label: 'نسبة العمولة', key: 'fees', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          type: 'actions',
          class: 'text-right',
          actions: [
            {
              text: 'show',
              icon: 'las la-eye',
              color: 'info',
              ifNavigate: true,
              routePath: 'show-inactive-clinics/:id'
            }
          ]
        }
      ]
    }
  },
  methods: {},
  created () {}
}
</script>

<style lang="scss" scoped>
.title {
  min-width: 150px;
  display: inline-block;
}
.title, .res {
  color: #333
}
</style>
